/**
 * This implementation is strongly coupled with the react-console-emulator package.
 * We only use CLI for debugging, so it's OK to cut some corners here.
 * {@link https://github.com/linuswillner/react-console-emulator}
 */

import { dependencies } from '@pn/core/dependencies';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  useAppStorage,
  useCurrentUserStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import download from 'downloadjs';
import { isEmpty, isNil } from 'lodash-es';
import { useTableFields } from './dataTableInteractions';

export function useCliCommands() {
  const { map, errorLogger } = dependencies;
  const mapboxMap = map._native;

  const { unitSystem } = useAppStorage();
  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { fields } = useTableFields(workspaceItemSelected?.dataType ?? '');

  return {
    'view-store': {
      description: 'Dump the store to the console.',
      fn: function () {
        console.log(dependencies.store.getState());
        return 'Store dumped to the console.';
      },
    },
    'view-map-style': {
      description: 'Dump the map style to the console.',
      fn: function () {
        const mapStyle = mapboxMap.getStyle();
        console.log(mapStyle);
        return `Map style dumped to the console.`;
      },
    },
    'view-zoom': {
      description: 'View the current zoom level.',
      fn: function () {
        const zoom = mapboxMap.getZoom();
        return `Zoom level: ${zoom}.`;
      },
    },
    'set-zoom': {
      description: 'Set the zoom level.',
      fn: function (zoom: number) {
        mapboxMap.setZoom(zoom);
        return `Zoom level set: ${zoom}.`;
      },
    },
    'export-geojson': {
      description: 'Export current layer/selection as GeoJSON.',
      fn: function () {
        if (isNil(workspaceItemSelected)) return 'Error: no layer selected.';

        if (workspaceItemSelected.itemType === 'annotation') {
          const geojsonString = JSON.stringify(
            (
              workspaceItemSelected.map.layers[0]
                .source as mapboxgl.GeoJSONSourceRaw
            ).data
          );
          download(geojsonString, 'annotation.geojson', 'application/json');
          return 'Annotation GeoJSON downloaded.';
        }

        const mapDataItems = getMapDataItems(workspaceItemSelected);
        if (isEmpty(mapDataItems)) return 'Error: no data items to export.';

        downloadDataItems({
          data: getMapDataItems(workspaceItemSelected),
          fields,
          mapping: workspaceItemSelected.mapping,
          unitSystem,
        }).geojson({
          fileName: (workspaceItemSelected.name || 'my_export') + '.geojson',
        });

        return 'GeoJSON downloaded.';
      },
    },
    'test-error': {
      description: 'Trigger a test error.',
      fn: function () {
        errorLogger.logGenericError(
          new Error('Test error triggered'),
          user?.id,
          'TestError'
        );
        return 'Test error triggered and logged.';
      },
    },
    'god-mode': {
      description: 'Activate god mode.',
      fn: function () {
        window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
      },
    },
  };
}
